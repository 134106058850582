import React from 'react';
import {blogPosts} from './components/data';
import {Box, Container, Link, Typography} from '@mui/material';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import logo from './media/logo.webp';
import "./App.css";
import Timeline from "./components/Timeline";
import MarkdownPost from "./components/MarkdownPost";
import RSSFeed  from "./components/RSSFeed";

import RssFeedIcon from '@mui/icons-material/RssFeed'; // Import the RSS icon

const TimelinePage = () => {
    return (
        <Container maxWidth="md" className="timeline">
            <Box sx={{my: 4}}>
                <Typography variant="h2" component="h1" align="center" gutterBottom>
                    <div className="logo-container">
                        <img className="logo" src={logo}/>
                    </div>
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    align="left"
                    paragraph
                    className="spacingText"
                >
                    I currently live in London and work on steerable diffusion models. <br/> I grew up in Russia and previously worked at Facebook. <br/>
                </Typography>
                <Box display="flex" justifyContent="left" alignItems="center" sx={{my: 4}}>
                    <Link href="https://www.instagram.com/a.chisler/" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon sx={{mr: 1}}/>
                    </Link>
                    <Link href="https://twitter.com/alex_chisler" target="_blank" rel="noopener noreferrer">
                        <TwitterIcon sx={{ml: 2, mr: 1}}/>
                    </Link>
                    <Link href="/rss.xml" target="_blank" rel="noopener noreferrer">
                        <RssFeedIcon sx={{ml: 2, mr: 1}}/> {/* Add this line for the RSS icon */}
                    </Link>
                </Box>

                <Timeline blogPosts={blogPosts}/>
            </Box>
        </Container>
    );
}


//App that allows to access blog routes by slug
//and stores the blog list at index
function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<TimelinePage/>}/>
                    <Route path="/posts/:slug" element={<MarkdownPost/>}/>
                     <Route path="/rss.xml" element={<RSSFeed/>}/>
                </Routes>
            </Router>
        </div>
    );
}


export default App;
